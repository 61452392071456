import {
  GET_FEEDBACKS,
  ADD_FEEDBACK,
  EDIT_FEEDBACK,
  GET_FEEDBACK,
  RESET_FEEDBACK,
  FEEDBACKS_ERROR,
} from "../types/feedback";

const initialState = {
  feedbacks: null,
  feedback: null,
  total_feedbacks: 0,
  loading: true,
  error: {},
  feedback_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FEEDBACKS:
      return {
        ...state,
        feedbacks: payload,
        loading: false,
        error: {},
      };

    case RESET_FEEDBACK:
      return {
        ...state,
        feedback: null,
        loading: true,
        error: {},
      };

    case ADD_FEEDBACK:
      return {
        ...state,
        feedback_message: payload,
        loading: false,
        error: {},
      };
    case GET_FEEDBACK:
      return {
        ...state,
        feedback: payload,
        loading: false,
        error: {},
      };
    case EDIT_FEEDBACK:
      return {
        ...state,
        feedback_message: payload,
        loading: false,
        error: {},
      };

    case FEEDBACKS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
