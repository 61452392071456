import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDQGmcdEN8XhdUQ9JsqwAHgmewphHlglpo",
  authDomain: "derma-blogs-c1c7d.firebaseapp.com",
  projectId: "derma-blogs-c1c7d",
  storageBucket: "derma-blogs-c1c7d.appspot.com",
  messagingSenderId: "323736060396",
  appId: "1:323736060396:web:32c2fdf46f28544e1f40c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
