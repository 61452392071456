import { db } from "../../shared/config";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  limit,
  Timestamp,
} from "firebase/firestore";
import {
  GET_BLOGS,
  ADD_BLOG,
  GET_BLOG,
  RESET_BLOG,
  BLOGS_ERROR,
  BLOG_START_POINT,
} from "../types/blog";

import { setAlert } from "./alert";
import { LIMIT } from "../../domain/constant";
//GET BLOGS
export const getBlogs =
  ({ lastData }) =>
  async (dispatch) => {
    try {
      const first = query(
        collection(db, "blogs"),
        orderBy("createdAt")
        // startAfter(lastData ? lastData : 0),
        // limit(LIMIT)
      );
      const querySnapshot = await getDocs(first);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      dispatch({
        type: BLOG_START_POINT,
        payload: lastVisible,
      });
      dispatch({
        type: GET_BLOGS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: BLOGS_ERROR,
      });
    }
  };

export const addBlog = (data) => async (dispatch) => {
  try {
    data.createdAt = Timestamp.fromDate(new Date());
    const docRef = await addDoc(collection(db, "blogs"), data);
    dispatch({
      type: ADD_BLOG,
    });
    dispatch(setAlert("Blog added successfully", "success"));
  } catch (e) {
    console.error("Error adding document: ", e);
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: BLOGS_ERROR,
    });
  }
};

export const getBlog = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_BLOG,
    });
    const docRef = doc(db, "blogs", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      data.id = id;
      dispatch({
        type: GET_BLOG,
        payload: data,
      });
    } else {
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: BLOGS_ERROR,
      });
    }
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: BLOGS_ERROR,
    });
  }
};
export const editBlog = (id, data) => async (dispatch) => {
  try {
    const docSnap = await updateDoc(doc(db, "blogs", id), data);
    dispatch(setAlert("Blog edited successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: BLOGS_ERROR,
    });
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  try {
    const docSnap = await deleteDoc(doc(db, "blogs", id));
    dispatch(setAlert("Blog deleted successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: BLOGS_ERROR,
    });
  }
};
