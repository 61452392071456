import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import { getBlogs, deleteBlog } from "../../store/actions/blogs";
import { useLocation } from "react-router-dom";
import { LIMIT } from "../../domain/constant";
const AllBlogs = ({
  getBlogs,
  deleteBlog,
  history,
  blog: { loading, blogs, start_blog_point },
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [page, setPage] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  useEffect(() => {
    async function allQuery() {
      if (deleteEntry) {
        await deleteBlog(deleteEntry);
      }
      const newPage = query.get("page") ? query.get("page") : 1;
      getBlogs({ lastData: start_blog_point });
      setPage(parseInt(newPage));
    }
    allQuery();
  }, [deleteEntry, query.get("page")]);

  const changeParams = ({ label, value }) => {
    query.set(`${label}`, value);
    history.push(`${window.location.pathname}?${query.toString()}`);
  };

  const deleteBlogClicked = (id) => {
    setDeleteEntry(id);
  };
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Blogs"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <div className="mb-4">
                        <Link
                          type="button"
                          to="/Blogs/add"
                          className="btn btn-light
                                  waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-1" /> Add blog
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table align-middle datatable dt-responsive table-check nowrap table-striped dataTable no-footer">
                          <thead>
                            <tr className="bg-transparent" role="row">
                              <th>#</th>
                              <th> Title </th>

                              <th> Meta Title </th>
                              <th> Meta Description </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading ? (
                              blogs &&
                              blogs.map((blog, index) => {
                                return (
                                  <tr key={`blog-${index}`}>
                                    <td className="sorting_1">
                                      {index + 1 + (page - 1) * LIMIT}
                                    </td>
                                    <td> {blog.title} </td>

                                    <td> {blog.meta_title} </td>
                                    <td> {blog.meta_description} </td>
                                    <td>
                                      <Link
                                        to={`/blogs/${blog.id}/view`}
                                        className="btn btn-soft-light"
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>
                                      <Link
                                        to={`/blogs/${blog.id}/edit`}
                                        className="btn btn-soft-light"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </Link>
                                      <button
                                        onClick={() =>
                                          deleteBlogClicked(blog.id)
                                        }
                                        className="btn text-danger"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={20} className="text-center">
                                  <Spinner />
                                </td>
                              </tr>
                            )}
                            {blogs && blogs.length === 0 && (
                              <tr>
                                <td colSpan={20}>No result found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!loading ? (
                      blogs && (
                        <div className="row">
                          <div className="col-sm-12 col-md-5"></div>
                          <div className="col-sm-12 col-md-7">
                            {blogs && blogs.length === LIMIT && (
                              <button
                                onClick={() =>
                                  changeParams({
                                    label: "page",
                                    value: page + 1,
                                  })
                                }
                                className="btn btn-secondary"
                              >
                                Next
                              </button>
                            )}
                          </div>
                        </div>
                      )
                    ) : (
                      <div>..</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ blog: state.blog });

const mapDispatchToProps = { getBlogs, deleteBlog };

export default connect(mapStateToProps, mapDispatchToProps)(AllBlogs);
