import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import blog from "./blog";
import feedback from "./feedback";
export default combineReducers({
  auth,
  blog,
  alert,
  feedback,
});
