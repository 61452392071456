import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import {
  getFeedbacks,
  getNextFeedback,
  getPreviousFeedback,
} from "../../store/actions/feedbacks";
import { LIMIT } from "../../domain/constant";
import Pagination from "../../components/common/Pagination";
import Filters from "../../components/common/Filters";
import { SEARCH_TERMS } from "../../shared/enums/feedbacks";
import DataTable from "../../components/common/DataTable";

const AllFeedbacks = ({
  getFeedbacks,
  deleteBlog,
  getNextFeedback,
  getPreviousFeedback,
  feedback: { loading, feedbacks },
}) => {
  const [page, setPage] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [q, setQ] = useState(null);
  const [term, setTerm] = useState(null);
  const [termField, setTermField] = useState(SEARCH_TERMS[0]);
  useEffect(() => {
    async function allQuery() {
      if (deleteEntry) {
        await deleteBlog(deleteEntry);
      }
      getFeedbacks({});
    }
    allQuery();
  }, [deleteEntry]);

  const nextButtonClicked = () => {
    if (feedbacks && feedbacks.length === LIMIT) {
      const lastBlog = feedbacks[feedbacks.length - 1];
      getNextFeedback({ last: lastBlog });
      setPage(page + 1);
    }
  };
  const prevBtnClicked = () => {
    if (page > 1) {
      const lastBlog = feedbacks[0];
      getPreviousFeedback({
        first: lastBlog,
      });
      setPage(page - 1);
    }
  };
  const filterClicked = () => {
    if (term && term.length > 0) {
      setPage(1);
      setQ(term);
      getFeedbacks({ term: term, termField: termField });
    }
  };
  const deleteBlogClicked = (id) => {
    setDeleteEntry(id);
  };
  const resetFilter = () => {
    setPage(1);
    setQ(null);
    setTerm("");
    getFeedbacks({});
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Feedbacks"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <hr />

                  <hr />

                  <Filters
                    q={q}
                    resetFilter={resetFilter}
                    setTerm={setTerm}
                    term={term}
                    filterClicked={filterClicked}
                    termField={termField}
                    setTermField={setTermField}
                    all_terms={SEARCH_TERMS}
                  />
                  <hr />
                  <div>
                    <DataTable
                      keys={[
                        { name: "name", value: "name" },
                        { name: "email", value: "email" },
                        { name: "phone", value: "phone" },
                        { name: "Counsellor name", value: "counsellor_name" },
                        { name: "Therapist Name", value: "therapist_name" },
                        { name: "Treatment Name", value: "treatment_name" },
                        {
                          name: "Response Time Of Staff",
                          value: "response_time_of_staff",
                        },
                        { name: "Booking Process", value: "booking_process" },
                        {
                          name: "quality of website",
                          value: "quality_of_website",
                        },
                        {
                          name: "front desk customer service",
                          value: "front_desk_customer_service",
                        },
                        {
                          name: "treatment rooms was clean",
                          value: "treatment_rooms_was_clean",
                        },
                        {
                          name: "overall atmosphere",
                          value: "overall_atmosphere",
                        },
                        {
                          name: "therapist was friendly",
                          value: "therapist_was_friendly",
                        },
                        {
                          name: "appointment on time",
                          value: "appointment_on_time",
                        },
                        {
                          name: "needs and concerened were addressed",
                          value: "needs_and_concerened_were_addressed",
                        },
                        { name: "would_recommend", value: "would_recommend" },
                        {
                          name: "would come in future",
                          value: "would_come_in_future",
                        },
                        {
                          name: "treatment to see us offer",
                          value: "treatment_to_see_us_offer",
                        },
                        {
                          name: "first here about service",
                          value: "first_here_about_service",
                        },
                        {
                          name: "anything we had done better",
                          value: "anything_we_had_done_better",
                        },
                        { name: "comments", value: "comments" },
                      ]}
                      data={feedbacks}
                      field={"feedbacks"}
                      page={page}
                      deleteBtnClicked={deleteBlogClicked}
                      loading={loading}
                    />
                    <Pagination
                      data={feedbacks}
                      page={page}
                      prevBtnClicked={prevBtnClicked}
                      nextButtonClicked={nextButtonClicked}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ feedback: state.feedback });

const mapDispatchToProps = {
  getFeedbacks,
  getNextFeedback,
  getPreviousFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFeedbacks);
